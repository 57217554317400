import React from "react";
import PropTypes from "prop-types";
import { useAppState } from "@state/state";
import classNames from "classnames";
import { VideoThumbnail } from "@common";
import {
  Text,
  RichText,
  Button,
  AuthButton,
  Icon,
  SVG,
  imageProps,
  AppLink,
} from "@ui";
import { t } from "@translations";
import { trackGAEvent } from "@utils";

const ResourceSlice = ({
  title: _title,
  description,
  links,
  buttons,
  iconName,
  videoThumbnail,
  illustration,
  orientation,
  sliceType,
}) => {
  const [{ user }, dispatch] = useAppState();

  const title =
    sliceType === "parallel_assessment_cta"
      ? `${t("Parallel Assessment")}: ${_title}`
      : _title;

  const handleParallelAssessmentClick = url => {
    trackGAEvent({ eventLabel: url });
    window.open(url, "_blank");
  };

  return (
    <div
      className={classNames({
        "flex bg-white border-2 border-gray-1 p-6": true,
        "flex-col-reverse sm:items-center": true,
        "sm:flex-row": orientation === "textLeft",
        "sm:flex-row-reverse": orientation === "textRight",
      })}
    >
      <div className="flex-1 flex flex-col">
        <Text preset="h3">{title}</Text>
        {description && description.html && (
          <RichText html={description.html} className="mt-2" />
        )}

        <div className="mt-2">
          {links.map(({ id, url, target, text }) => (
            <React.Fragment key={id}>
              {sliceType === "parallel_assessment_cta" ? (
                <>
                  {user.loggedIn ? (
                    <Button
                      preset="empty"
                      text={text}
                      className="text-blue mr-4"
                      iconName={target === "_blank" ? "externalLink" : null}
                      iconClassName="w-3 h-3"
                      orientation="textLeft"
                      onClick={() => handleParallelAssessmentClick(url)}
                    />
                  ) : (
                    <AuthButton
                      preset="empty"
                      text={text}
                      className="text-blue mr-4"
                      iconName={target === "_blank" ? "externalLink" : null}
                      iconClassName="w-3 h-3"
                      orientation="textLeft"
                      modalName="signInModal"
                    />
                  )}
                </>
              ) : (
                <AppLink to={url} target={target} className="inline-block mr-4">
                  <Button
                    preset="empty"
                    text={text}
                    className="text-blue"
                    iconName={target === "_blank" ? "externalLink" : null}
                    iconClassName="w-3 h-3"
                    orientation="textLeft"
                  />
                </AppLink>
              )}
            </React.Fragment>
          ))}

          {buttons.map(({ id, text, onClick, iconName: _iconName }) => (
            <Button
              key={id}
              preset="empty"
              text={text}
              iconName={_iconName}
              iconClassName="w-3 h-3"
              onClick={
                videoThumbnail
                  ? () =>
                      dispatch({
                        type: "openModal",
                        name: "videoEmbed",
                        context: {
                          title,
                          youtubeVideoId: videoThumbnail.youtubeVideoId,
                        },
                      })
                  : onClick
              }
              orientation="textLeft"
              className="inline-block mr-4 text-blue"
            />
          ))}
        </div>
      </div>

      {iconName && (
        <Icon
          name={iconName}
          className={classNames({
            "mx-auto mb-4 sm:mr-6 sm:mb-0": true,
            "w-36 h-36": description && description.html,
            "w-24 h-24": !description || !description.html,
          })}
        />
      )}
      {videoThumbnail && (
        <VideoThumbnail
          title={title}
          youtubeVideoId={videoThumbnail.youtubeVideoId}
          thumbnail={videoThumbnail.thumbnail}
          className="w-full mb-4 sm:w-48 sm:mb-0 sm:ml-4"
        />
      )}
      {illustration && (
        <div
          className={`w-full sm:w-36 sm:h-36 mx-auto mb-4 sm:mb-0 bg-${illustration.bgColor.toLowerCase()}`}
        >
          <div className="relative" style={{ paddingBottom: "100%" }}>
            <span className="absolute bottom-0 right-0 left-0">
              <SVG svg={illustration.text} />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

ResourceSlice.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      target: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      iconName: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  iconName: PropTypes.string,
  videoThumbnail: PropTypes.shape({
    youtubeVideoId: PropTypes.string,
    thumbnail: imageProps.props,
  }),
  illustration: PropTypes.shape({
    bgColor: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  orientation: PropTypes.oneOf(["textLeft", "textRight"]),
  sliceType: PropTypes.string.isRequired,
};

ResourceSlice.defaultProps = {
  description: null,
  links: [],
  buttons: [],
  iconName: null,
  videoThumbnail: null,
  illustration: null,
  orientation: "textLeft",
};

export default React.memo(ResourceSlice);
