import React from "react";
import RichTextSlice from "./RichTextSlice";
import ImageSlice from "./ImageSlice";
import ResourceSlice from "./ResourceSlice";
import TestimonialSlice from "./TestimonialSlice";
import CTASlice from "./CTASlice";
import BylineSlice from "./BylineSlice";
import DecorativeImageSlice from "./DecorativeImageSlice";
import { VideoEmbed } from "@common";
import { Container } from "@ui";

/* eslint react/prop-types: 0 */

const Block = ({ block }) => {
  const { sliceType } = block;
  switch (sliceType) {
    case "copy":
      return <RichTextSlice {...block} />;
    case "large_copy":
      return <RichTextSlice {...block} size="lg" />;
    case "images":
      return <ImageSlice {...block} />;
    case "video":
      return <ResourceSlice {...block} />;
    case "parallel_assessment_cta":
      return <ResourceSlice {...block} />;
    case "curriculum_cta":
      return <ResourceSlice {...block} />;
    case "general_cta":
      return <CTASlice {...block} />;
    case "testimonial":
      return <TestimonialSlice {...block} />;
    case "video_embed":
      return <VideoEmbed {...block} />;
    case "title_byline":
      return <BylineSlice {...block} />;
    case "decorative_image":
      return <DecorativeImageSlice {...block} />;

    default:
      return null;
  }
};

const getContainerSize = ({ sliceType, images }) => {
  let size = "cb";

  if (sliceType === "images" && images && images.length > 1) {
    size = "full";
  }

  if (sliceType === "testimonial" || sliceType === "decorative_image") {
    size = "lg";
  }

  return size;
};

const ContentBuilder = ({ blocks, wrapInContainer, className: _className }) => {
  return (
    <div className={_className}>
      {blocks.map((block, idx) => (
        <Container
          key={block.id}
          size={wrapInContainer ? getContainerSize(block) : "full"}
          className={idx < blocks.length - 1 ? "mb-10 md:mb-12" : ""}
        >
          <Block block={block} />
        </Container>
      ))}
    </div>
  );
};

export default React.memo(ContentBuilder);
