import React from "react";
import PropTypes from "prop-types";
import { Image, imageProps, Carousel } from "@ui";

const ImageSlice = ({ images }) => {
  const useCarousel = images.length > 1;
  const captions = images.map(image =>
    image && image.caption ? image.caption.html : null
  );

  return (
    <>
      {useCarousel ? (
        <Carousel infinite showDots showButtons captions={captions}>
          {images.map(image => (
            <div key={image.id} style={{ height: "70vh", maxHeight: "840px" }}>
              <Image image={image} objectFit="cover" disableDrag />
            </div>
          ))}
        </Carousel>
      ) : (
        <div className="relative w-full">
          <Image image={images[0]} objectFit="contain" className="shadow-lg" />
        </div>
      )}
    </>
  );
};

ImageSlice.propTypes = {
  images: PropTypes.arrayOf(imageProps.props).isRequired,
};

export default ImageSlice;
