import React from "react";
import PropTypes from "prop-types";
import { RichText } from "@ui";

const RichTextSlice = ({ html, size }) => (
  <RichText
    html={html}
    className={`RichText--content-builder RichText--${size}`}
  />
);

RichTextSlice.propTypes = {
  html: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["default", "lg"]),
};

RichTextSlice.defaultProps = {
  size: "default",
};

export default RichTextSlice;
