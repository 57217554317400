import React from "react";
import PropTypes from "prop-types";
import { Text, HeadingAccent } from "@ui";
import { t } from "@translations";

const BylineSlice = ({ title, author, role }) => (
  <>
    <HeadingAccent />
    <Text preset="h1" className="text-blue mb-4">
      {title}
    </Text>

    <Text preset="h3" className="text-gray">
      {`${t("By")} ${author}`}
    </Text>

    {role && (
      <Text
        preset="label"
        tag="p"
        className="font-bold text-gray-5 mt-1 leading-tight"
      >
        {role}
      </Text>
    )}
  </>
);

BylineSlice.propTypes = {
  title: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  role: PropTypes.string,
};

BylineSlice.defaultProps = {
  role: "",
};

export default BylineSlice;
