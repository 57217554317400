import React from "react";
import { Image, imageProps } from "@ui";
import { getTailwindUtility } from "@utils";

const DecorativeImage = ({ image }) => {
  const colors = getTailwindUtility("colors");
  const filter = colors.blue["3"].replace("#", "");

  return (
    <div
      className="relative w-full rounded-lg overflow-hidden"
      style={{ paddingBottom: "50%" }}
    >
      <Image image={{ ...image, filter }} />
    </div>
  );
};

DecorativeImage.propTypes = {
  image: imageProps.props.isRequired,
};

export default DecorativeImage;
