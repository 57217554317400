import React from "react";
import PropTypes from "prop-types";
import { Text, Button, AppLink } from "@ui";
import { t } from "@translations";

const CTASlice = ({ heading, ctaText, ctaLink }) => (
  <div className="bg-white shadow-card rounded-lg p-6 md:p-8 lg:py-12 text-center">
    <Text preset="h3" className="text-gray">
      {heading}
    </Text>
    <AppLink to={ctaLink.url} target={ctaLink.target}>
      <Button
        preset="blue"
        text={ctaText || t("learn more")}
        className="mt-6"
      />
    </AppLink>
  </div>
);

CTASlice.propTypes = {
  heading: PropTypes.string.isRequired,
  ctaText: PropTypes.string.isRequired,
  ctaLink: PropTypes.shape({
    url: PropTypes.string.isRequired,
    target: PropTypes.string,
  }).isRequired,
};

export default CTASlice;
