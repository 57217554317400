import React from "react";
import PropTypes from "prop-types";
import { Text, RichText, Image, imageProps } from "@ui";

const TestimonialSlice = ({ text, person, role, photo }) => {
  return (
    <div className="bg-gray-1 rounded-lg p-6 sm:p-8 md:p-16">
      <div className="z-10">
        <RichText
          html={text.html}
          className="RichText--quote font-serif italic font-bold text-xl md:text-2xl"
        />
      </div>

      <div className="flex mt-6">
        <div className="relative w-12 h-12 rounded-full border-4 border-blue-1 overflow-hidden mr-4">
          <Image image={photo} />
        </div>
        <div className="flex-1">
          <Text preset="h3" className="mb-1">
            {person}
          </Text>
          <Text
            preset="label"
            className="font-bold text-xs text-gray-5 leading-firm mt-1"
          >
            {role}
          </Text>
        </div>
      </div>
    </div>
  );
};

TestimonialSlice.propTypes = {
  text: PropTypes.shape({
    html: PropTypes.string.isRequired,
  }).isRequired,
  person: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  photo: imageProps.props.isRequired,
};

export default TestimonialSlice;
