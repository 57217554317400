import React from "react";
import { graphql } from "gatsby";
import { keysToCamel } from "@utils";
import Page from "../../pageTemplates/Page";
import mergePrismicPreview from "../mergePrismicPreview";
import resolveByPrefix from "../dataResolvers/resolveByPrefix";
import resolveSlices from "../dataResolvers/resolveSlices";
import resolveImageData from "../dataResolvers/resolveImageData";

/* eslint react/prop-types: 0 */

const dataResolver = ({ prismicPages }) => {
  const prefixes = ["meta_", "hero_", "prefooter_"];
  const { prefixed, unPrefixed } = resolveByPrefix(prismicPages.data, prefixes);
  const { meta, prefooter, ...prefixedRest } = prefixed;
  const { body, ...unPrefixedRest } = unPrefixed;

  const resolved = {
    data: {
      uid: prismicPages.uid,
      ...prefixedRest,
      ...unPrefixedRest,
      meta: {
        ...meta,
        image: resolveImageData(meta.image)[0],
      },
      body: resolveSlices(body),
      prefooter: {
        ...prefooter,
        backgroundImage: resolveImageData(prefooter.background_image)[0],
      },
    },
  };

  return keysToCamel(resolved);
};

const PageQuery = ({ data: _data }) => {
  const merged = mergePrismicPreview(_data);
  const data = dataResolver(merged);
  return <Page {...data} />;
};

export default PageQuery;

export const query = graphql`
  query($uid: String!) {
    prismicPages(uid: { eq: $uid }) {
      uid
      data {
        meta_title
        meta_description
        meta_image {
          filter
          focal_point_x
          focal_point_y
          image {
            alt
            url
            dimensions {
              height
              width
            }
          }
        }
        hero_heading
        hero_description {
          html
        }
        body {
          ... on PrismicPagesBodyCopy {
            id
            slice_type
            primary {
              rich_text {
                html
              }
            }
          }
          ... on PrismicPagesBodyLargeCopy {
            id
            slice_type
            primary {
              rich_text {
                html
              }
            }
          }
          ... on PrismicPagesBodyImages {
            id
            slice_type
            items {
              image {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
              focal_point_y
              focal_point_x
              filter
              caption {
                html
              }
            }
          }
          ... on PrismicPagesBodyTestimonial {
            id
            slice_type
            primary {
              text {
                html
              }
              person
              role
              photo {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
          ... on PrismicPagesBodyCurriculumCta {
            id
            slice_type
            primary {
              heading
              description {
                html
              }
              material {
                id
                uid
                url
                document {
                  ... on PrismicLessons {
                    id
                    uid
                    type
                    data {
                      illustration {
                        text
                      }
                      cor_question {
                        id
                        document {
                          ... on PrismicCorQuestions {
                            id
                            data {
                              color
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicAssessments {
                    id
                    uid
                    type
                    data {
                      illustration {
                        text
                      }
                      cor_question {
                        id
                        document {
                          ... on PrismicCorQuestions {
                            id
                            data {
                              color
                            }
                          }
                        }
                      }
                    }
                  }
                  ... on PrismicCollections {
                    id
                    uid
                    type
                    data {
                      illustration {
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPagesBodyGeneralCta {
            id
            slice_type
            primary {
              heading
              cta_text
              cta_link {
                url
                target
              }
            }
          }
          ... on PrismicPagesBodyVideoEmbed {
            id
            slice_type
            primary {
              video {
                document {
                  ... on PrismicVideos {
                    data {
                      title
                      youtube_video_id
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPagesBodyTitleByline {
            id
            slice_type
            primary {
              title
              author
              role
            }
          }
          ... on PrismicPagesBodyDecorativeImage {
            id
            slice_type
            primary {
              image {
                url
                alt
                dimensions {
                  width
                  height
                }
              }
            }
          }
        }
        prefooter_heading
        prefooter_description {
          html
        }
        prefooter_cta_link {
          url
          target
        }
        prefooter_cta_text
        prefooter_background_image {
          filter
          focal_point_x
          focal_point_y
          image {
            alt
            url
            dimensions {
              height
              width
            }
          }
        }
      }
    }
  }
`;
