import React, { useEffect } from "react";
import { useAppState } from "@state/state";
import { IndexHero } from "@modules/indexes/index";
import { ContentBuilder } from "@modules/contentBuilder/index";
import { SEO, PreFooter } from "@common";
import { Section } from "@ui";

/* eslint react/prop-types: 0 */

const Page = ({ data }) => {
  const { meta, hero, prefooter, body } = data;
  const [, dispatch] = useAppState();

  useEffect(() => {
    dispatch({
      type: "setHeaderTheme",
      headerTheme: {
        bgColor: "blue",
        showSearch: true,
        lockup: {
          size: "small",
          color: "blue",
        },
      },
    });
  }, []);

  return (
    <>
      <SEO {...meta} />
      <IndexHero heading={hero.heading} description={hero.description} />

      <Section>
        <ContentBuilder blocks={body} wrapInContainer />
      </Section>

      <PreFooter {...prefooter} />
    </>
  );
};

export default Page;
